<template>
  <div class="container">
    <a-divider></a-divider>
    <div class="title">
      <h2 style="height: 50px; width: 700px; display: flex; justify-content: space-between; align-items: center;">
        <span style="float: left; font: Microsoft Yahei">评论区</span>
        <span style="float: right; font-size: 14px;line-height: 17px;cursor: pointer">
          评论数: <span style="color: rgb(0, 98, 255); font-size: 30px; vertical-align: middle;">{{commentsNum}}</span>
        </span>
      </h2>
    </div>
    <div class="inputArea">
      <!-- <a-input-search placeholder="登录后评论..." enter-button="登陆并评论" size="large" @search="onSearch"> -->
      <a-input-group compact>
        <a-input placeholder="发表友善评论..." size="large" v-model:value="value" allowClear style="width: calc(100% - 200px)"></a-input>
        <a-button  type="primary" @click="onComment" style="height: 40px; margin-left:10px">
          {{btnCnt}}
        </a-button>
      </a-input-group>
    </div>
    <div class="commentsListArea">
      <a-empty v-if="commentsList.length===0">
        <span slot="description">暂无评论数据！</span>
      </a-empty>
      <div v-for="(item, index) in commentsList" :key="index" 
      :style="userName !==null&&userName===item.commentUserName ? enlight:commentItem"
      >
        <div class="avater">
          <img src="../../assets/avater.png" v-if="item.avatar == null" alt="" style="width: 35px;height: 35px; border-radius: 50%;" @click="goUser(item.uid)">
          <img :src="item.avatar" v-if="item.avatar != null" alt="" style="width: 35px;height: 35px; border-radius: 50%;" @click="goUser(item.uid)">
        </div>
        <div class="commentTextArea">
          <!-- <span style="float: left;">{{item.userName}}</span> -->
          <h3 style="height: 20px; width: 600px; display: flex; justify-content: space-between; align-items: center;">
            <span style="float: left; font: Microsoft Yahei; color: #008cd2;" @click="goUser(item.uid)">{{item.userName}}</span>
            <span style="float: right; font-size: 10px;line-height: 17px; color: #999;; cursor: pointer; font-family: Arial;">{{item.pubTime}}</span>
          </h3>
          <p style=" text-align: left;">{{item.content}}</p>
          <ul class="tags">
            <li class="tagItem" @click="doLikes(item)">
              <like-outlined v-if="cmtClickedIds.length === 0 || cmtClickedIds.indexOf(item.id) < 0" />
              <like-filled v-if="cmtClickedIds.length >0 && cmtClickedIds.indexOf(item.id) >= 0" />
              {{item.likes}}
            </li>
            <li class="tagItem"><message-outlined />回复</li>
            <li class="tagItem" v-if="userName!=item.userName"><close-outlined />举报</li>
            <li class="tagItem" v-if="userName===item.userName" @click="delCmt(item)"><close-outlined />删除</li>
          </ul>
          <div style="margin-top: 10px;">
            <a-divider></a-divider>
          </div>
        </div>
      </div>
      <a-pagination
        v-if="totalNum>0"
        :total="totalNum"  
        show-quick-jumper :show-total="total => `Total ${total} items`" 
        :pageSize="pageSize" 
        @change="onChange"
      />
    </div>
  </div>
</template>
<script>
  import { isLogin, getUserName } from '../../utils/account.js';
  // import { wrComment,clickLikes,renderLikeIconList  } from '../../../api/comment.js';
  import * as CMT from '../../api/comment.js';
  import * as BLOG from '../../api/blog.js';
  import * as ACCOUNT from '../../api/account.js';
  import { CloseOutlined, MessageOutlined, LikeOutlined, LikeFilled } from '@ant-design/icons-vue';
  export default {
    name: 'Comments',
    components: {
      CloseOutlined,
      MessageOutlined,
      LikeOutlined,
      LikeFilled
    },
    props: {
      commentsNum: {
        type: Number,
        default: 0
      },
      commentsList: {
        type: Array,
        default: ()=>[]
      },
      totalNum: {
        type: Number,
        default: 0,
      },
      pageSize: {
        type: Number,
        default: 0,
      },
      blogId: {
        type: String,
        default: '',
      },
      changePage: {
        type: Function
      },
      reloadCmt: {
        type: Function
      },
    },
    data () {
      return {
        value: '',
        btnCnt: '登陆并评论',
        userName: '',
        cmtClickedIds:[],
        commentItem: "height: 140px; margin-top: 20px",
        enlight: "height: 140px; margin-top: 20px;border: 1px solid rgb(79, 175, 253)"
      }
    },
    mounted () {
      this.updateBtn()
      this.renderLikesIcon(null)
      // this.getUserName();
    },
    methods: {
      onComment() {
        // console.log(this.value);
        if(isLogin() === false) {
          this.$message.warning('请先登陆再评论！')
          this.$router.replace({
            path: '/login',
            query: {redirect: this.$router.currentRoute.fullPath}
          })
        }else if (this.value===null|| this.value === '') {
          this.$message.error('评论不能为空');
        }else {
          let data = new FormData();
          data.append('content', this.value);
          data.append('bid', this.blogId);
          CMT.writeBlogCmt(data).then(res=>{
            // console.log(res);
            if(res.status == 200 && res.data.code === 200) {
              this.reloadCmt(this.blogId);
              this.value = '';
            }
          })
        }
      },
      updateBtn(){
        // console.log(getUserName());
        //获取用户名
        this.userName = getUserName();
        if(isLogin()){
          this.btnCnt = '发表评论';
        }
      },
      doLikes(cmt) {
        // console.log(cmt)
        if(!isLogin()) {
          let _that = this
          this.$confirm({
            title: '信息提示',
            content: '请先登陆再点赞',
            okText: '去登陆',
            okType: 'primary',
            cancelText: '取消',
            cancelType: 'warning',
            onOk() {
              // console.log('OK');
              _that.$router.push({
                path: '/login',
                query: {redirect: _that.$router.currentRoute.fullPath}
              })
            },
            onCancel() {
              // console.log('Cancel');
            },
          });
          return;
        }
        let params = new FormData()
        params.append('cid', cmt.id)
        params.append('ty',1)
        BLOG.clickLikes(params).then(res=>{
          // console.log(res);
          this.reloadCmt(cmt.bid);
        })
      },
      renderLikesIcon(list) {
        // console.log('list', list)
        if(list == null) {
          return
        }
        let cmtIds = list.map(item=>{
          return item.id;
        })
        // let cmtIds = ['659cfd6c771519023b5d7c2ddddc40e8']
        // console.log('ids', cmtIds)
        let params = new FormData()
        params.append('cmtIds', cmtIds)
        CMT.renderCmtLikeList(params).then(res=>{
          // console.log('likes list', res)
          this.cmtClickedIds = res.data.data;
        })
      },
      onChange(page, size) {
        // console.log('page', page)
        this.$emit('changePage', page)
      },
      delCmt(item) {
        // console.log(item)
        let _that = this
        this.$confirm({
          title: '删除您的评论',
          content: '一经删除将无法恢复，确定删除？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            let params = new FormData()
            params.append('bid', item.bid)
            params.append('cid', item.id)
            params.append('content', item.content)
            ACCOUNT.delMyCmt(params).then(res=>{
              // console.log(res)
              if(res.data.data) {
                _that.$message.success('删除成功');
                _that.$emit('changePage', 1)
              }
            })
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      },
      goUser(uid) {
        this.$router.push({
          path: `/space/${uid}`,
        })
      }
    },
    watch: {
      commentsList: {
        handler(newVal,oldVal) {
          // console.log(newVal)
          // console.log('old', oldVal)
          if(isLogin() && newVal.length > 0) {
            this.renderLikesIcon(newVal)
          }
        },
        deep: true,
        immediate: true,
      },
    }
  }
</script>
<style scoped>
  .container {
    display: block;
    width: 700px;
  }
  .title {
    height: 60px;
    width: 700px;
  }
  .commentsListArea {
    margin-top: 20px;
    display: block;
  }
  .commentItem {
    height: 120px;
    margin-top: 20px;
    border:1px solid rgb(79, 175, 253)
  }
  .enlight {
    height: 120px;
    margin-top: 20px;
    border-top:1px solid rgb(79, 175, 253)
  }
  .avater {
    float: left;
    width: 35px;
    height: 35px;
    margin-right: -65px;
    padding: 0 15px;
    border-radius: 50%;
    position: relative
  }
  .commentTextArea {
    float: right;
    /* 宽度之和小于等于700px */
    width: 620px;
  }
  .tags {
    margin: 0;
    padding: 0;
    float: right;
    position: relative;
    z-index: 4;
    background-color: #fff;
    height: 26px;
    border: solid 1px #ddd;
    border-radius: 2px;
    list-style: none;
  }
  .tagItem {
    float: left;
    text-align: center;
    height: 21px;
    min-width: 60px;
    position: relative;
    border-right: solid 1px #ddd;
  }
</style>