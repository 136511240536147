<template>
  <div class="content" >
    <a-spin size="large" v-if="blog.title === ''">
      <h1>正在努力加载.....</h1>
    </a-spin>
    <div class="body" v-if="blog.title !== ''">
      <div class="mainContent">
        <h1 class="blogTitle">{{blog.title}}</h1>
        <div class="blogInfo">
          <span style="float: left;"><a-tag color="#7B8184">标签:</a-tag>
            <a-tag v-for="(item,index) in blog.tags" :key="index" :color="color[index%7]">{{item.name}}</a-tag>
          </span>
          <span>{{blog.pubTime}} <span @click="goAuthor">作者:{{blog.author}}</span></span>
          <span style="float: right; font-size: 16px; color: rgb(92, 90, 90);"><eye-outlined />  {{blog.viewNum}}</span>
          <a-divider></a-divider>
        </div>
        <div>
          <div style="float: left; width: 80px;">
            <div class="comment">
              <div class="commentIcon">
                <div>
                  <comment-outlined :style="{ fontSize: '36px', color: '#08c'}" @click="goComment" />
                </div>
                <span style="margin-top: 5px auto; display: block;width: 80px;font: 32px Georgia; color: #0679fc; text-align: center;"
                @click="goComment"
                >
                {{commentsPage.totalNum}}</span>
              </div>
              <a-divider></a-divider>
              <div class="likesIcon">
                <div @click="doBlogLikes">
                  <like-outlined v-if="!ifClickedLikes" :style="{ fontSize: '36px'}" />
                  <like-filled v-if="ifClickedLikes" :style="{ fontSize: '36px'}" />
                </div>
                <span style="margin-top: 5px auto; display: block;width: 80px;font: 32px Georgia; color: #0679fc; text-align: center;">{{blog.likes}}</span>
              </div>
              <a-divider></a-divider>
              <div class="starIcon">
                <div @click="clickBlogStars">
                  <star-outlined v-if="!ifClickedStars" :style="{ fontSize: '36px'}" />
                  <star-filled v-if="ifClickedStars" :style="{ fontSize: '36px'}" />
                </div>
                <span style="margin-top: 5px auto; display: block;width: 80px;font: 32px Georgia; color: #0679fc; text-align: center;">{{blog.stars}}</span>
              </div>
            </div>
          </div>
          <p v-html="blog.content" class="rawPost" @click="previewImg($event)"></p>
          <!-- <div class="previewImg" v-if="previewImgInfo.preview">
            
          </div> -->
          <a-modal v-model:visible="previewImgInfo.preview" title="图片预览" @ok="closePreview" style="width: 850px; height: 500px;">
            <img :src="previewImgInfo.src" width="800" height="400" />
          </a-modal>
          <!-- <div id="bcontent" class="rawPost"> -->
          <!-- </div> -->
          <div class="commentArea" id="commentArea">
            <comments 
              :commentsNum="commentsPage.totalNum" 
              :commentsList="commentsPage.commentsList" 
              :totalNum="commentsPage.totalNum"  
              :pageSize="commentsPage.pageSize" 
              @changePage="changePage"
              :blogId = "blog.blogId"
              :reloadCmt="reloadCmt"
            >
          </comments>
          </div>
        </div>
      </div>
      <div class="leftSide">
        <!-- <related-blog :blogs="relatedBlogs.related" 
          :loading="relatedBlogs.rtloading"
        >
        </related-blog> -->
        <related-blog
          v-if="blog.blogId!==''"
          :bid="blog.blogId"
          :tags="blog.tags"
        >
        </related-blog>
      </div>
    </div>
  </div>
</template>
<script>
  import Comments from './Comment.vue';
  import { isLogin } from '../../utils/account.js';
  import * as BLOG from '../../api/blog.js';
  import * as CMT from '../../api/comment.js';
  import { CommentOutlined, LikeOutlined, LikeFilled, StarOutlined, StarFilled, EyeOutlined } from '@ant-design/icons-vue';
  import RelatedBlog from '@/components/sider/RelatedBlog'
  import Prism from "prismjs"
  import 'prismjs/themes/prism.css'
  import "prismjs/themes/prism-okaidia.css"
  import 'prismjs/plugins/line-numbers/prism-line-numbers'
  import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
  import 'prismjs/plugins/toolbar/prism-toolbar'
  import 'prismjs/plugins/toolbar/prism-toolbar.css'
  import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'
  export default {
    name: 'BlogPage',
    components: {
      Comments,
      CommentOutlined,
      LikeOutlined,
      LikeFilled,
      StarOutlined,
      StarFilled,
      EyeOutlined,
      RelatedBlog
    },
    data () {
      return {
        blog: {
          title: '',
          blogId: '',
          pubTime: '',
          author: '',
          uid: '',
          viewNum: 0,
          content: '<p>test</p><pre><code class="language-python">print("hello")</code></pre>',
          likes: 0,
          stars: 0,
          tags:[],
          cmtNum: 0,
        },
        previewImgInfo: {
          src: '',
          preview: false,
        },
        color: ['red', 'pink', 'orange', 'green', 'cyan','blue', 'purple'],
        ifClickedLikes: false,
        ifClickedStars: false,
        relatedBlogs: {
          rtloading: true,
          rtIndex: 1,
          totalPage: 0,
          related:[],
        },
        commentsPage: {
          totalNum: 0,
          pageSize: 6,
          commentsList: [],
        },
        uaTimer: null,
      }
    },
    mounted () {
      // console.log('route=',this.$route);
      this.loadBlogById(this.$route.query.id)
      this.loadBlogCmts(this.$route.query.id, 1)
      this.setVisitorInfo(this.$route.query.id)
      this.renderBlogLikesIcon(this.$route.query.id)
      this.loadBlogLikesNum(this.$route.query.id)
      this.loadBlogStarsNum(this.$route.query.id)
      this.renderBlogStarsIcon(this.$route.query.id)
      //访问到页面时回滚至顶部
      window.scrollTo(0, 0)
      // this.renderCodeBlock()
    },
    watch: {
      '$route.query.id': {
        handler: function(id) {
          // console.log(id)
          if(id!==undefined){
            this.loadBlogById(id)
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      goComment() {
        window.location.href='#commentArea';
      },
      renderCodeBlock() {
        let pres = document.querySelectorAll('pre')
        // console.log(pres)
        for (let i = 0; i < pres.length; i++) {
          pres[i].className += ' line-numbers'
        }
        Prism.highlightAll()
      },
      //previewImg
      previewImg(e) {
        // console.log(e)
        if(e.target.tagName=='IMG') {
          // console.log(e.target.src)
          this.previewImgInfo.src = e.target.src
          this.previewImgInfo.preview = true
        }
      },
      closePreview(){
        this.previewImgInfo.preview = false
      },
      //change comments page 
      changePage(page) {
        // console.log('page change', page)
        this.loadBlogCmts(this.blog.blogId, page)
      },
      loadBlogById(id) {
        let data = new FormData();
        // console.log('id=',id);
        data.append('id', id);
        let _that = this;
        BLOG.searchBlogById(data).then(res=>{
          // console.log(res);
          if(res.status === 200 && res.data.data) {
            this.blog.title = res.data.data.title
            this.blog.blogId = res.data.data.id
            this.blog.author = res.data.data.author
            this.blog.pubTime = res.data.data.pubTime
            this.blog.viewNum = res.data.data.viewNum
            this.blog.content = res.data.data.content
            this.blog.tags = res.data.data.tags
            this.blog.uid = res.data.data.uid
            // _that.loadRelatedBlogs(res.data.data.id, res.data.data.tags)
            this.renderCodeBlock()
          } else {
            this.$router.push({
              path: '/404-notfound'
            })
          }
        })
      },
      //getPostCommentsById
      loadBlogCmts(id, page) {
        let data = new FormData();
        data.append('id', id);
        data.append('page', page);
        CMT.loadBlogCmtsByBid(data).then(res=>{
          // console.log('cmts', res);
          if(res.status === 200) {
            this.commentsPage.totalNum = res.data.data.total;
            this.commentsPage.commentsList = res.data.data.records;
            this.commentsPage.pageSize = res.data.data.size;
          }
        })
      },
      setVisitorInfo(id) {
        clearTimeout(this.uaTimer)
        this.uaTimer = setTimeout(()=>{
          let params = new FormData()
          params.append('id', id)
          BLOG.recordVisitorInfo(params).then(res=>{
            // console.log('user agent', res)
          })
        }, 10000)
      },
      loadRelatedBlogs(bid, tags) {
        let tids = tags.map(item=>{
          return item.id;
        })
        // console.log('tids', tids)
        this.relatedBlogs.rtloading = true;
        let data = new FormData();
        data.append('tid', tids);
        data.append('bid', bid)
        data.append('page', this.relatedBlogs.rtIndex);
        data.append('size', 5);
        BLOG.searchRelatedBlogs(data).then(res=>{
          console.log('related', res)
          this.relatedBlogs.rtloading = false;
          if(res.data.code === 200 && res.data.data.total > 0) {
            this.relatedBlogs.related = res.data.data.records;
            this.relatedBlogs.rtIndex =+ 1
            if(this.relatedBlogs.rtIndex > res.data.data.pages) {
              this.relatedBlogs.rtIndex = 1
            }
          }
        })
      },
      reloadCmt(blogId) {
        this.loadBlogCmts(blogId,1);
      },
      remindLogin() {
        if(!isLogin()) {
          let _that = this
          this.$confirm({
            title: '信息提示',
            content: '请先登陆再点赞',
            okText: '去登陆',
            okType: 'primary',
            cancelText: '取消',
            cancelType: 'warning',
            onOk() {
              // console.log('OK');
              _that.$router.push({
                path: '/login',
                query: {redirect: _that.$router.currentRoute.fullPath}
              })
            },
            onCancel() {
              // console.log('Cancel');
            },
          });
          return true;
        }
        return false;
      },
      doBlogLikes() {
        if(this.remindLogin()) {
          return;
        }
        let params = new FormData()
        params.append('cid', this.blog.blogId)
        params.append('ty',0)
        BLOG.clickLikes(params).then(res=>{
          // console.log('click like', res);
          this.loadBlogLikesNum(this.blog.blogId);
          this.renderBlogLikesIcon(this.blog.blogId);
        })
      },
      renderBlogLikesIcon(blogId) {
        // console.log(isLogin())
        if (isLogin()) {
          let params = new FormData()
          params.append('cid', blogId)
          params.append('ty', 0)
          BLOG.ifUserClickedLikesIcon(params).then(res=>{
            // console.log('if clicked', res)
            this.ifClickedLikes = res.data.data;
          })
        }
      },
      loadBlogLikesNum(id) {
        // console.log('id', id)
        let params = new FormData()
        params.append('id', id)
        BLOG.getBlogLikesNum(params).then(res=>{
          // console.log('blog likes', res)
          this.blog.likes = res.data.data;
        })
      },
      loadBlogStarsNum(id) {
        let params = new FormData()
        params.append('id', id)
        BLOG.getBlogStarsNum(params).then(res=>{
          this.blog.stars = res.data.data
        })
      },
      clickBlogStars() {
        if(this.remindLogin()) {
          return;
        }
        // console.log('click star')
        let params = new FormData()
        params.append('bid', this.blog.blogId)
        BLOG.clickStars(params).then(res=>{
          this.loadBlogStarsNum(this.blog.blogId);
          this.renderBlogStarsIcon(this.blog.blogId)
        })
      },
      renderBlogStarsIcon(id) {
        if (isLogin()) {
          let params = new FormData()
          params.append('bid', id)
          BLOG.ifUserClickedStar(params).then(res=>{
            // console.log('if clicked', res)
            this.ifClickedStars = res.data.data;
          })
        }
      },
      goAuthor() {
        this.$router.push({
          path: `/space/${this.blog.uid}`,
        })
      },
      // initContent(content) {
      //   // console.log('content', content)
      //   this.$nextTick(()=>{
      //     ClassicEditor.create(document.querySelector("#bcontent"), {
      //       toolbar: null
      //     }).then(editor=>{
      //       editor.enableReadOnlyMode('bcontent');
      //       editor.setData(this.blog.content)
      //       // console.log('editor', editor)
      //     }).catch(err=>{
      //       console.log('display blog', err)
      //     })
      //   })
      // }
    }
  }
</script>

<style scoped>
  .content {
    width: 80%;
    min-height: 300px;
    height: 100%;
    margin: auto;
    background-image: linear-gradient(to bottom right, #f5f6fb, #dbe3fc);
  }
  .body{
    display: flex;
    height: 100%;
    min-height: 800px;
  }
  .mainContent {
    width: 850px;
    /* float: left; */
  }
  .blogTitle {
    font: 700 38px MicrosoftYaHei Bold,MicrosoftYaHei,Arial,Hiragino Sans GB,STHeiti,Helvetica Neue,Helvetica,Microsoft Yahei,WenQuanYi Micro Hei,sans-serif;
    color: #404040;
    text-align: center;
  }
  .blogInfo {
    margin-top: 18px;
    margin-bottom: 20px;
    font: 14px Arial,Hiragino Sans GB,STHeiti,Helvetica Neue,Helvetica,Microsoft Yahei,WenQuanYi Micro Hei,sans-serif;
    color: #999;
  }
  .leftSide {
    float: right;
    width: 300px;
    display: block;
    margin-left: 30px;
  }
  .rtTitle {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rtTitle:hover {
    text-decoration:underline;
  }
  .rtNum {
    font-size: 12px;
    color: #969696;
  }
  .comment{
    width: 80px;
    height: 100%;
  }
  .commentIcon {
    text-align: center;
    width: 80px;
    height: 80px;
  }
  .likesIcon {
    text-align: center;
    width: 80px;
  }
  .starIcon {
    text-align: center;
    width: 80px;
  }
  .commentArea {
    float: right; 
    width: 700px;
  }
  .rawPost {
    float: right; 
    width: 760px;
    background-color: #fff;
    border-radius: 16px;
    padding: 30px 30px;
    font-size: 15px; 
    line-height: 1.74;
    text-align: left;
  }
  .rawPost >>> img {
    display: block;
    margin: 20px auto;
    width: 680px;
    height: 400px;
  }
  .ant-divider-horizontal {
    margin: 10px 0;
  }
</style>