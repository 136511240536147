<template>
  <div class="rList">
    <h2 style="height: 50px; display: flex; justify-content: space-between; align-items: center;">
      <span style="float: left;">相关推荐</span>
      <span style="float: right; font-size: 14px;line-height: 17px;cursor: pointer;" @click="loadRelatedBlogs">
        <reload-outlined :spin="loading" />换一换</span>
    </h2>
    <a-divider style="margin: -10px 0px;"></a-divider>
    <a-empty v-if="blogs.length===0" :image-style="{height: '60px'}"><span>暂无相关博客推荐!</span></a-empty>
    <div class="img_list">
      <div v-for="(item, index) in blogs" :key="index" class="rtItem" @click="goBlog(item.id)" >
        <span class="rtTitle">{{item.title}}</span>
        <br />
        <span class="rtNum">阅读次数:{{item.viewNum}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { ReloadOutlined } from '@ant-design/icons-vue';
  import { defineComponent, reactive, ref, onMounted } from 'vue';
  import router from '../../router';
  import * as BLOG from '../../api/blog.js';
  export default defineComponent( {
    name: 'RelatedBlog',
    components: {
      ReloadOutlined
    },
    props: {
      bid: null,
      tags: null
    },
    setup(props, ctx) {
      const goBlog = (id) => {
        router.push({
          path: '/blog',
          query: {
            id: id
          }
        })
      }
      const loading = ref(true)
      const blogs = ref([])
      const pagination = reactive({
        current: 1,
        pages: 0,
        total: 0
      })

      const loadRelatedBlogs = () => {
        let tids = props.tags.map(item=>{
          return item.id;
        })
        // console.log('tids', tids)
        let data = new FormData();
        data.append('tid', tids);
        data.append('bid', props.bid)
        data.append('page', pagination.current);
        data.append('size', 5);
        BLOG.searchRelatedBlogs(data).then(res=>{
          // console.log('related', res)
          loading.value = false;
          if(res.data.code === 200 && res.data.data.total > 0) {
            blogs.value = res.data.data.records;
            //切换
            pagination.current = (res.data.data.current+1)%res.data.data.pages
            pagination.pages = res.data.data.pages
            pagination.total = res.data.data.total
          }
        })
      }
      onMounted(() => {
        loadRelatedBlogs()
      })
      return {
        goBlog,
        loading,
        blogs
      }
    },
  })
</script>
<style scoped>
  .rList {
    margin-top: 120px;
    width: 300px;
    min-height: 160px;
    height: 400px;
    background-color: #fff;
    border-radius: 16px;
  }
  .img_list {
    text-align: left;
  }
  .rtItem {
    margin-top: 10px;
  }
  .rtItem:hover {
    color: rgb(125, 141, 250)
  }
  .rtTitle {
    font-size: 16px;
  }

</style>